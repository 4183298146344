.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
}

.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ComponentBg {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.1;
}
