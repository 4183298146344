.ConnectContainer {
  .CustomBtn {
    width: 11rem !important;
    height: 11rem !important;
    position: relative;
    border-radius: 100%;
    background: transparent;
    border: none;
    outline: none;
    background-image: url(../../assets/img/custom-btn.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    color: white;
    font-family: "PTSansNarrow";
    font-size: 2.3rem;
    &[disabled] {
      opacity: 0.4 !important;
    }
  }
}

.ConnectModalBox {
  width: 300px;
  min-height: 220px;
  overflow: hidden;
  .FormHeader {
    h3 {
      width: fit-content;
      cursor: pointer;
      color: var(--text-color);
      &.ActiveSegment {
        opacity: 1;
        color: var(--text-color);
      }
    }
  }
  .FormBody {
    min-height: 90px;
    transition: all 0.5s ease;
    .BodySegment {
      &.Login {
        position: absolute;
        left: 0;
        transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      &.Register {
        position: absolute;
        left: 364px;
        transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }

    &.Animation {
      min-height: 150px;
      .Login {
        left: -364px;
      }
      .Register {
        left: 0;
      }
    }
  }
  .FormFooter {
    .Info {
      cursor: pointer;
    }
    .ForgotPassText {
      margin-top: 20px;
    }
  }
}

.PassPopper {
  background: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 111;
  padding: 10px;
  border-radius: 8px;
  gap: 7px;
  font-size: 0.9rem;
  top: -45px;
  color: black;

  .PassIcon {
    color: red !important;
    &.Checked {
      color: green !important;
    }
  }

  span {
    gap: 4px;
    display: flex;
    align-items: center;
  }
}
