:root {
  // --theme-background: #282c34;
  --theme-background: #0a2f58;
  --theme-background-dark: #02264d;
  --theme-background-light: #02264d75;

  --theme-helper-dark: #041526;
  --light-theme-background: #2a2e35;
  --border: rgba(255, 255, 255, 0.3);
  --text-color: rgb(255, 255, 255);
  --shadow-color: #161616;
  --skeleton-background: rgba(0, 0, 0, 0.2);
  --secondary-color: #1274cf;
  --secondary-color-light: #3988d1;
  --secondary-color-text: rgba(25, 118, 210, 0.5);
  --error-color: #c66464;
}

.BackScreen {
  height: 0 !important;
  overflow: hidden !important;
  z-index: 0;
  position: relative;
}
.OverflowHidden {
  overflow: hidden !important;
  overflow-y: hidden !important;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: unset;
}

body {
  margin: 0;
  font-family: "PTSansNarrow";
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--theme-background-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--text-color);
}

ul {
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.DisplayNone {
  display: none !important;
}
.Disabled {
  opacity: 0.8;
}

.ComponentContainer {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 30px 0 50px;
  border-radius: 10px;
}
.PrimatyBtn {
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
  font-family: "PTSansNarrow";
  background: var(--theme-helper-dark);
  border: 1px solid var(--secondary-color-light);
  height: 45px;
  border-radius: 10px;
  padding: 0 20px;
  width: 140px;
  &[disabled] {
    opacity: 0.4;
  }
}
.SecondaryBtn {
  background-image: url("./assets/img/secondary-btn.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 1.2rem;
  font-family: "PTSansNarrow";
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 130px;
  &[disabled] {
    opacity: 0.4 !important;
  }
}

.PrimaryInput {
  height: 41px;
  border-radius: 5px;
  outline: none;
  padding: 0 20px;
  font-size: 1.2rem;
  background: var(--theme-background);
  border: 2px solid var(--secondary-color-light);
  font-weight: 600;
  color: white;
  &::placeholder {
    color: white;
    opacity: 0.7;
  }
}

.MainSection {
  width: 350px !important;
  position: relative;
  border-radius: 10px;
  padding: 30px 20px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  min-height: 500px;
  backdrop-filter: blur(20px);
  > h2 {
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }
}
.SectionIcon {
  position: absolute;
  top: 0;
}
.SectionTitle {
  margin-top: 70px;
  text-align: center;
  font-size: 2rem;
  text-shadow: 0px 5px 0px black;
  stroke: black;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}

#root {
  width: 100%;
}
#unity-progress-bar-full {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  color: white;
  font-size: 1.8rem;
}

.MuiModal-root {
  &.Blured {
    .MuiBackdrop-root {
      backdrop-filter: blur(10px);
    }
  }
  .MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: var(--theme-background);
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
      0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    padding: 32px;
    border-radius: 20px;
    min-width: 300px;
  }
  .ModalProgress {
    margin-top: -32px;
    height: 10px;
    width: calc(100% + 64px);
    margin-left: -32px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .ModalBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .FormHeader {
      padding: 10px;
      border-bottom: 1px solid var(--box-border);
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        font-size: 23px;
        color: var(--secondary-color-light);
        &.Active {
          color: var(--text-color);
        }
      }
      h6 {
        margin-top: 20px;
        font-size: 20px;
        color: var(--text-color);
      }
      &.MultiItem {
        flex-direction: row !important;
        justify-content: space-around;
      }
    }
    .FormBody {
      display: flex;
      gap: 15px;
      position: relative;
      flex-direction: column;
      align-items: center;
      .BodySegment {
        gap: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      button {
        min-height: 55px;
        max-height: 55px;
        width: 100%;
        flex: 1;
        outline: none;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 19px;
        transition: all 0.2s ease;
        opacity: 1;
        cursor: pointer;
        height: 42px;
        cursor: pointer;
        &[disabled] {
          opacity: 0.8;
        }
        &.CancelButton {
          color: var(--border);
          background: transparent;
        }
        &.OkButton {
          color: var(--text-color);
          background: var(--secondary-color-light);
        }
      }
    }
    .FormFooter {
      .Info {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--border);
        font-size: 14px;
        width: 100%;
        justify-content: center;
        transition: all 0.2s ease;
        .material-icons {
          font-size: 17px;
        }
        .InfoItem {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.MuiDataGrid-root {
  color: white !important;
  font-family: "PTSansNarrow" !important;
  border: none !important;
  width: 100%;
  margin-bottom: -5px;

  .MuiDataGrid-columnHeader {
    outline: none !important;
  }

  .MuiDataGrid-columnHeader--sortable {
    button {
      color: white !important;
    }
  }
  .MuiDataGrid-columnHeader--sorted {
    button {
      color: white !important;
    }
  }

  .MuiDataGrid-main {
    background: var(--theme-background) !important;
    // border-radius: 10px;
    padding: 20px !important;
  }
  .MuiDataGrid-footerContainer {
    background: var(--theme-background) !important;
  }

  .MuiDataGrid-columnHeaders {
    padding: 0 20px !important;
    background: var(--theme-background-dark) !important;
    border: none !important;

    .MuiDataGrid-columnSeparator {
      display: none !important;
    }
  }

  .MuiDataGrid-footerContainer {
    border: none !important;
  }

  .MuiDataGrid-row {
    margin: 5px 0;
    border-radius: 10px;
    background: var(--theme-background-dark);
    .MuiDataGrid-cell {
      border: none;
      outline: none;
    }
  }

  .MuiTablePagination-root {
    color: white;
    button {
      color: white;
    }
    .MuiTablePagination-actions {
      display: flex;
    }
  }
  .MuiDataGrid-menuIcon {
    display: none;
  }
}
.Popover {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  background: var(--theme-helper-dark);
  .PopoverHeader {
    color: var(--secondary-color-light);
  }
  .PopoverBody {
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--text-color);
    .PopoverItem {
      cursor: pointer;
      border-bottom: 1px solid var(--skeleton-background);
      padding-bottom: 3px;
      display: flex;
      gap: 7px;
      align-items: center;
      .MuiIcon-root {
        font-size: 1.3rem;
      }
    }
  }
}
.MuiPopover-paper {
  background-color: transparent !important;
}

@font-face {
  // font-family: "LilitaOne";
  // src: url("./fonts/LilitaOne-Regular.ttf");
  font-family: "PTSansNarrow";
  src: url("./fonts/PTSansNarrow-Bold.ttf");
}

$small: 700px;
$medium: 900px;

//some CSS
@media screen and (max-width: $small) {
  .App {
    .MainSection {
      padding: 30px 10px 20px !important;
    }
    .Home {
      .Contents {
        margin-top: 0 !important;
        .DashboardComponent {
          width: 98vw;
          .ChartContainer {
            .Charts {
              width: unset !important;
            }
          }
        }
      }
    }
    .Game {
      margin-bottom: 10px !important;
      .CanPlay {
        display: none;
      }
      .InventoryComponent {
        width: 90% !important;
        margin: 10px 0 !important;
        margin-bottom: unset !important;
        transform: unset !important;
      }
    }
    .NavbarContainer {
      .Links {
        height: unset !important;
        ul {
          overflow-x: scroll;
          padding: 10px 20px;
          gap: 20px !important;
        }
      }
      .SectionsTitle {
        font-size: 12px;
        padding: 20px 0;
      }
      .Sections {
        height: unset;
        padding: 15px !important;
        gap: 20px;
      }
    }
  }
  //do Smth
}
@media screen and (min-width: $medium) {
  //do Smth
}
