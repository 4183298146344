.Game {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .GameContent {
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.45, 0.92);
    gap: 20px;
    &.CanPlay {
      // transform: translateX(20%);
    }
  }

  .Logo {
    height: 200px;
  }
  .ConnectButton {
    min-height: 90px;
    display: flex;
    align-items: center;
    button {
      width: 200px;
    }
  }
}
