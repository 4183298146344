.NavbarContainer {
  width: 100%;
  padding-bottom: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  .Links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background: var(--theme-background-dark);
    // border-bottom: 2px solid var(--skeleton-background);

    .Logo {
      height: 80px;
    }

    ul {
      margin: 0 80px;
      display: flex;
      list-style: none;
      color: white;
      gap: 30px;
      font-size: 1.3rem;
      align-items: center;
      a {
        color: white;

        text-decoration: none;
        white-space: nowrap;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .ShortenAddress {
      display: flex;
      align-items: center;
      padding: 0 10px;
      opacity: 1;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
      .material-icons {
        padding: 0 5px;
      }
      .WalletAdress {
        padding-right: 10px;
      }

      cursor: pointer;
      color: var(--text-color);
    }
  }
}
