.DataTableComponent {
  display: flex;
  flex-direction: column;
  background: var(--theme-background);
  border-radius: 10px;
  padding-bottom: 10px;
  > h2 {
    margin: 5px;
    padding: 10px 15px;
    border-radius: 5px;
  }

  .TableHeaders {
    display: flex;
    gap: 10px;
    padding: 0 20px;
    opacity: 0.8;
    font-size: 1.1rem;
    margin: 10px 0;
    color: var(--secondary-color-light);
    padding-bottom: 5px;
    overflow-y: auto;
    > div {
      white-space: nowrap;
      &:first-of-type {
        padding-left: 10px;
      }
    }
  }
  .TableRows {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px;
    opacity: 0.9;
    font-size: 0.9rem;
    overflow-y: scroll;
    max-height: 400px;
    .infinite-scroll-component {
      display: flex;
      flex-direction: column;
      gap: 10px;
      opacity: 0.9;
      // overflow: hidden !important;
    }

    .TableRow {
      display: flex;
      gap: 10px;
      > div {
        // border: 1px solid var(--theme-background-dark);
        padding: 10px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        // overflow-x: scroll;
      }
      &:nth-child(2n) {
        background: var(--theme-background-dark);
      }
      &:nth-child(2n + 1) {
        background: var(--theme-background-light);
      }
    }
    .UnAuthorized {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100px;
    }
  }
}
