.FooterComponent {
  margin: 40px 0;
  .Content {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    .Brand {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      img {
        width: 160px;
      }
      > div {
        max-width: 330px;
        line-height: 24px;
        text-align: center;
      }
    }
    .Itemlist {
      text-align: center;
      flex: 1;
      align-self: center;
      ul {
        list-style: none;
        li {
          padding: 10px;
          a {
            color: var(--text-color);
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 17px;
            img,
            svg {
              width: 28px;
              height: 28px;
              border: 1px solid var(--box-border);
              border-radius: 50%;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}
