.DashboardComponent {
  backdrop-filter: blur(10px);
  width: calc(70vw);
  display: flex;
  flex-direction: column;
  gap: 30px;
  .ChartContainer {
    background: var(--theme-background);
    border-radius: 10px;
    padding-bottom: 10px;
    > h2 {
      margin: 10px;
      padding: 10px 15px;
      border-radius: 5px;
    }
    .Charts {
      width: 50%;
      padding: 0 20px;
    }
  }
}
