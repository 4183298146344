.InventoryComponent {
  // width: 400px;
  // height: 400px;
  // transform: translateX(-20%);
  animation: comes-left 0.5s linear;
  margin-bottom: 0px;
  position: relative;
  .SectionIcon {
    left: 0;
    border-top-left-radius: 10px;
  }
  .SkeletonRectangle {
    width: 100%;
    margin: 10px 0;
    border-radius: 7px;
  }
  .LoadingOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px);
    z-index: 2;
  }
  .Tab {
    margin-top: 25px;
    width: 100%;
    border-radius: 10px;
    overflow: auto;
    height: 100%;
    background: var(--theme-background);
    .TabHeader {
      display: flex;
      justify-content: space-between;
      background-color: rgb(28 60 95);

      > div {
        flex: 1;
        // border-top-right-radius: 10px;
        // border-top-left-radius: 10px;
        background: var(--theme-background-dark);
        font-weight: 600;
        padding: 10px;
        color: var(--border);
        font-size: 1rem;
        position: relative;
        transition: all 0.25s ease;
        cursor: pointer;
        box-shadow: none;
        min-width: 60px;
        text-align: center;
        &.active {
          background: var(--theme-background);
          color: var(--color);
          &:first-of-type {
            box-shadow: 20px 0px 14px -10px rgba(0, 0, 0, 0.3);
          }
          &:last-of-type {
            box-shadow: -20px 0px 14px -10px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
    .TabBody {
      padding: 0 20px 20px;
      overflow: auto;
      max-height: 283px;
      min-height: 283px;

      .NotAuthorized {
        text-align: center;
        margin-top: 30px;
      }

      hr {
        border: 1px solid var(--secondary-color-light);
      }
      .EmtyRow {
        margin-top: 10px;
        text-align: center;
        opacity: 0.8;
        font-size: 14px;
      }
      > div {
        animation: fade-in 0.2s ease;
        display: block;
        // display: none;
        // &.active {
        //   display: block !important;
        // }
      }

      .TableRow {
        font-size: 0.8rem;
        display: flex;
        padding: 5px;
        background: var(--theme-background-dark);
        margin: 10px 0;
        border-radius: 7px;
        height: 40px;
        align-items: center;
        position: relative;
        transition: all 0.5s ease;
        > div {
          white-space: nowrap;
          overflow: auto;
          text-overflow: ellipsis;
          flex: 0.75;
          &:nth-child(2) {
            flex: 3;
          }
          &.RowBody {
            display: flex;
            flex-direction: column;
            // gap: 7px;
            > h3 {
              font-size: 16px;
            }
          }
          // &:last-of-type {
          //   color: var(--error-color);
          //   cursor: pointer;
          //   text-align: end;
          // }
        }
        .Symboles {
          display: flex;
          gap: 10px;
          .SymboleContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 13px;
            gap: 3px;
            opacity: 0.8;

            .ContentTop {
              display: flex;
              align-items: center;
              justify-content: center;
              align-items: center;
              gap: 15px;
              .Symbol {
                display: flex;
                align-items: center;
              }
            }
            .SectionHeader {
              text-align: center;
              width: 90px;
              font-size: 0.7rem;
              border-radius: 10px;
              color: var(--secondary-color-light);
              background-color: var(--theme-helper-dark);
            }
          }

          img {
            width: 15px;
          }
          .NftId {
            font-size: 1rem;
            padding: 5px 10px 10px 0px;
            border-right: 1px solid var(--secondary-color-light);
          }
        }
        .ImageCol {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          .ImageThumbnail {
            width: 32px;
            height: 32px;
            background-color: var(--border);
            border-radius: 10px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }
    }
    .AnimationComesLeft {
      // opacity: 1;
      // transform: translateX(0%);
      animation: fade-left2right 0.5s cubic-bezier(0.17, 0.67, 0.45, 0.92);
    }
    .AnimationGoesLeft {
      opacity: 0;
      transform: translateX(-100%);
      // animation: fade-right2left 2s cubic-bezier(0.17, 0.67, 0.45, 0.92);
    }
    @keyframes comes-left {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }
      to {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    @keyframes fade-left2right {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }
      to {
        opacity: 1;
        transform: translateX(0%);
      }
    }
    @keyframes fade-right2left {
      from {
        opacity: 1;
        transform: translateX(0%);
      }
      to {
        opacity: 0;
        transform: translateX(-100%);
      }
    }
  }
}
