.Home {
  .Contents {
    margin-top: 80px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    backdrop-filter: blur(10px);
    flex-wrap: wrap;
  }
}

