.ProfileComponent {
  backdrop-filter: blur(10px);
  width: 70%;
  .Tabs {
    min-height: 100%;
  }
  .ChartContainer {
    display: flex;
    gap: 20px;
    .ChartSection {
      width: calc(50% - 50px);
      border: 1px solid var(--border);
      padding: 20px;
      border-radius: 10px;
    }
  }
  .Top {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow: hidden;
    .Container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.Password {
        margin-top: -177px;
        transition: all 0.4s ease-in-out;

        &.PasswordAnim {
          margin-top: 0;
        }
      }

      &.Email {
        margin-top: -59px;
        transition: all 0.2s ease-in-out;

        &.EmailAnim {
          margin-top: 0;
        }
      }

      .PrimaryInput {
        min-height: 45px;
      }

      .SecondaryBtn {
        min-width: 180px;
        min-height: 55px;
        max-height: 55px;
        width: 100%;
      }
    }
  }
  .MuiTabs-root {
    width: 100%;
    .MuiTabs-flexContainer {
      height: 60px;
    }
    .MuiTabs-indicator {
      background-color: var(--text-color);
    }
  }
  .MuiTabPanel-root {
    width: 100%;
  }
  .MuiButtonBase-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    &.Mui-selected {
      color: white;
    }
  }
}
